@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
/* You can add global styles to this file, and also import other style files */
@import "~ngx-smart-modal/ngx-smart-modal";

@media print {
  html, body {
    height: 99%;
    overflow: hidden;
 }

  body * {
    visibility: hidden;
  }

  @page {
    size: auto;
    margin: 0;
    height: auto !important;
}
  .client-receipt, .client-receipt * {
    visibility: visible;
  }
  .client-receipt {
    position: absolute;
    left: 0;
    top: 0;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.client-receipt{
  visibility: hidden;
  height:0;
}
body {
  background-color: rgb(240,240,240);
  font-family: 'Roboto';
}

.online-payment-button{
  background: linear-gradient(to right, rgba(67,108,146,1) 0%,rgba(100,150,239,1) 50%,rgba(99,222,251,1) 100%);
}

.responsive-setter{
  @apply 2xl:mx-60 xl:mx-48 lg:mx-10 md:mx-48 sm:mx-10;
}

.total-amount {
  background: linear-gradient(to right,  #070707 0%,#7C7C7C 50%,#CACACA 100%);
}

input.ng-touched.ng-invalid {
  border : 2px solid red;
}


label img {
  width: 5vw;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
}


.image-on-screens {
  width: 6rem;
}


input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

.small-text {
  font-size: 2px;
}
